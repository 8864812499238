import create from "zustand"
import countries from "./countries"

export const useContactStore = create((set) => ({
  meta: {
    id: "contact",
    title: "Contact Us - The Partnership Economy",
    description:
      "Contact us for any media, event, or book inquiries relating to The Partnership Economy.",
  },
  pageTitle: "contact",
  pageBody: "For any media, event, or book inquiries, please use the following form.",
  mktoFormId: "mktoForm_2495",
  form: {
    requiredText: "Required",
    invalidText: "Invalid",
    submitButtonText: "Submit inquiry",
    submitSuccessText: "Thank you! You'll be hearing from us soon.",
    firstNamePlaceholder: "First name",
    lastNamePlaceholder: "Last name",
    companyNamePlaceholder: "Company name",
    jobTitlePlaceholder: "Job title",
    emailPlaceholder: "Email",
    messagePlaceholder: "Message",
    countryPlaceholder: "Country",
    countries,
  },
}))
